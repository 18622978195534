// Swiper styles
import 'swiper/swiper-bundle.css';

// подключение jquery и fancybox
var $ = require("jquery");
window.jQuery = $;
require("@fancyapps/fancybox");
require('@fancyapps/fancybox/dist/jquery.fancybox.css')

// scss
import './assets/scss/main.scss'

import './assets/js/main';
